import '../styles/globals.css'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import { SessionProvider, useSession } from "next-auth/react"
import { Analytics } from '@vercel/analytics/react';
import Layout from '../components/layouts/welcomeLayout'




export type ProtectedNextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
  auth: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: ProtectedNextPageWithLayout
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <SessionProvider session={session}>
      {/* <Layout> */}
      {!Component.auth ? (
        getLayout(<Component {...pageProps} />)
      ) : (
        <Protected>
          {getLayout(<Component {...pageProps} />)}
        </Protected>
      )}
      {/* </Layout> */}
      <Analytics />
    </SessionProvider>
  );
}

function Protected({ children }: { children: any }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true })

  if (status === "loading") {
    return <div>Loading...</div> // TODO customize
  }

  return children
}

export default MyApp
